/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import {
  _Table,
  Pagination,
  RowsPerPageSelect,
} from "../../../components/Table";
import {
  GET_ALL_SCHOOLS_QUERY,
  GET_SCHOOLS,
  GET_TRANSACTION_REPORT,
} from "../../../Qurries";
import { Link } from "react-router-dom";
import { dashboardContext } from "../Dashboard";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { amountFormat } from "../../../utils/amountFormat";
import { getStartAndEndOfMonth } from "../../../utils/getStartAndEndOfMonth";
import TransactionDateFilter, {
  formatDate,
} from "./components/TransactionDateFilter";
import { HiMiniXMark } from "react-icons/hi2";
import { endOfDay, startOfDay } from "date-fns";
import { CustomDropdownIndicator } from "../Settlement/Settlement";
import Select from "react-select";
import MixFilter from "./components/MixFilter";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { GET_TRANSACTION_AMOUNT } from "../../../utils/getTransactionAmount";
import axios from "axios";
import { getPaymentMode } from "../../../utils/getPaymentMode";
import Aword from "../../../assets/a_round.svg";
export const handleCheckboxChange = (
  category: any,
  option: any,
  setFilters: any,
) => {
  setFilters((prevFilters: any) => ({
    ...prevFilters,
    [category]: {
      ...prevFilters[category],
      [option]: !prevFilters[category][option],
    },
  }));
};

export const payment_method_map: any = {
  credit_card: "Credit Card",
  debit_card: "Debit Card",
  net_banking: "Net Banking",
  UPI: "UPI",
  upi: "UPI",
  wallet: "Wallet",
  credit_card_emi: "Credit Card EMI",
  debit_card_emi: "Debit Card EMI",
  cardless_emi: "Cardless EMI",
  pay_later: "Pay Later",
  upi_credit_card: "UPI Credit Card",
};
export function getVendorAmount({ array, orderAmount }: any) {
  const res = array
    ?.map((info: any) => {
      const { percentage, amount } = info;
      let split_amount = amount;
      if (percentage) {
        split_amount = (orderAmount * percentage) / 100;
      }
      return split_amount;
    })
    ?.reduce((acc: any, curr: any) => acc + curr, 0);

  return res;
}

function Transaction() {
  const [transactionData, setTransactionData] = useState<any>([]);
  const { startDate, endDate } = getStartAndEndOfMonth();
  const [searchText, setSearchText] = useState<string>("");
  const [isCustomSearch, setIsCustomSearch] = useState(false);
  const [searchFilter, setSearchFilter] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(1);
  const { setTransaction, user } = useContext(dashboardContext);
  const [transactionReportData, settransactionReportData] = useState<any>([]);
  const [isDateRangeIsSelected, setIsDateRangeIsSelected] = useState(false);
  const [transactionTotal, setTransactionAmount] = useState(0);
  const [orderAmountTotal, setOrderAmountTotal] = useState(0);
 
  const [type, setType] = useState("");
  const [dateRange, setDateRange] = useState("");

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [refetchLoading, setRefetchLoading] = useState(false);

  const [status, setStatus] = useState<any>(null);
  const [selectSchool, setSelectSchool] = useState<any>("");
  const [schoolId, setSchoolId] = useState<any>(null);
  const [itemsPerRow, setItemsPerRow] = useState({ name: 10 });
  const [transactionAmountDetails, setTransactionAmountDetails] =
    useState<any>(null);

  const [filters, setFilters] = useState<any>({
    paymentMode: {
      credit_card: false,
      credit_card_emi: false,
      upi: false,
      wallet: false,
      pay_later: false,
      cardless_emi: false,
      net_banking: false,
      debit_card_emi: false,
      debit_card: false,
      na: false,
      qr: false,
    },
  });

  const {
    data: transactionReport,
    loading: transactionReportLoading,
    refetch,
  } = useQuery(GET_TRANSACTION_REPORT, {
    variables: {
      startDate: startDate,
      endDate: endDate,
      page: currentPage.toString(),
      //status: status.toUpperCase(),
      //school_id: schoolId,
      limit: itemsPerRow.name.toString(),
      isQRCode: true,
    },
  });

  useEffect(() => {
    if (transactionReport?.getTransactionReport?.transactionReport) {
      const formattedData =
        transactionReport?.getTransactionReport?.transactionReport.map(
          (row: any, index: number) => ({
            schoolName: row.school_name,
            transactionTime: row.createdAt,
            orderID: row.collect_id,
            transactionAmount:
              row.transaction_amount === null ? "--" : row.transaction_amount,
            paymentMode:
              row.payment_method === "" || row.payment_method === null
                ? "NA"
                : payment_method_map[row.payment_method],
            orderAmount: row.order_amount === null ? "--" : row.order_amount,
            transactionStatus:
              row.status === null ? "NA" : row.status?.toLowerCase(),
            schoolId: row.school_id === null ? "NA" : row.school_id,
            ...row,
            serialNumber: (currentPage - 1) * itemsPerRow.name + 1 + index,
          }),
        );
      setTransactionData(formattedData);
    }
  }, [transactionReport]);

  // useEffect(() => {
  //   const amount: any = [];
  //   const order_amount: any = [];
  //   transactionData.filter((d: any) => {
  //     if (d.transactionStatus === "success") {
  //       amount.push(d.transaction_amount);
  //       order_amount.push(d.order_amount);
  //     }
  //   });

  //   setOrderAmountTotal(
  //     order_amount?.reduce((a: any, current: any) => a + current, 0)
  //   );
  //   setTransactionAmount(
  //     amount?.reduce((a: any, current: any) => a + current, 0)
  //   );
  // }, [transactionData]);

  const refetchDataFetch = async ({
    start_date,
    end_date,
    page,
    status,
    school_id,
    isCustomSearch,
    searchFilter,
    searchParams,
    payment_modes,
    isQrCode,
  }: {
    start_date?: any;
    end_date?: any;
    page?: String;
    status?: String;
    school_id?: String;
    isCustomSearch?: boolean;
    searchFilter?: String;
    searchParams?: String;
    limit?: String;
    payment_modes?: string[] | null;
    isQrCode?: boolean;
  }) => {
    setRefetchLoading(true);

    try {
      const res = await refetch({
        startDate: start_date,
        endDate: end_date,
        page,
        status,
        school_id,
        isCustomSearch: isCustomSearch,
        searchFilter,
        searchParams,
        payment_modes: isQrCode ? null : payment_modes,
        isQRCode: isQrCode,
      });
      if (res) {
        setRefetchLoading(false);
        setTransactionData(
          res?.data?.getTransactionReport.transactionReport.map(
            (row: any, index: any) => ({
              schoolName: row.school_name,
              transactionTime: row.createdAt,
              orderID: row.collect_id,
              transactionAmount:
                row.transaction_amount === null ? "--" : row.transaction_amount,
              paymentMode:
                row.payment_method === "" || row.payment_method === null
                  ? "NA"
                  : payment_method_map[row.payment_method],
              orderAmount: row.order_amount === null ? "--" : row.order_amount,
              transactionStatus:
                row.status === null ? "NA" : row.status?.toLowerCase(),
              schoolId: row.school_id === null ? "NA" : row.school_id,
              ...row,
              serialNumber: (currentPage - 1) * itemsPerRow.name + 1 + index,
            }),
          ),
        );
      }
    } catch (error) {
      if (error) {
        setRefetchLoading(false);
      }
    }
  };

  const onApplyFilter = () => {
    const filteredTransactions = transactionData.filter((transaction: any) => {
      if (
        transaction?.paymentMode == null ||
        transaction?.transactionStatus == null ||
        transaction.schoolName == null
      ) {
        return false;
      }

      const isStatusFilterActive = Object.values(filters?.status).some(Boolean);
      const isSchoolFilterActive = Object.values(filters?.schoolName).some(
        Boolean,
      );
      const isPaymentFilterActive = Object.values(filters?.paymentMode).some(
        Boolean,
      );

      const statusMatch =
        !isStatusFilterActive ||
        filters?.status[transaction?.transactionStatus?.toLowerCase()];

      const schoolMatch =
        !isSchoolFilterActive || filters?.schoolName[transaction?.schoolName];

      const paymentMatch =
        !isPaymentFilterActive ||
        filters?.paymentMode[
          transaction?.paymentMode?.replaceAll(" ", "_")?.toLowerCase()
        ];

      if (
        !isStatusFilterActive &&
        !isSchoolFilterActive &&
        !isPaymentFilterActive
      ) {
        return true;
      }

      return statusMatch && schoolMatch && paymentMatch;
    });
  };

  const GET_TRANSACTION_AMOUNT = async (
    start_date: String,
    end_date: String,
    trustee_id: String,
    school_id: String,
    status: String,
    mode?: string[] | null,
    isQrCode?: boolean,
  ) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_PAYMENT_BACKEND_URL}/edviron-pg/get-transaction-report-batched`,
        {
          trustee_id: trustee_id,
          school_id: school_id,
          start_date: start_date,
          end_date: end_date,
          status: status,
          mode: isQrCode ? null : mode,
          isQRPayment: isQrCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => {
        setTransactionAmountDetails(response.data.transactions[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GET_TRANSACTION_AMOUNT(
      isDateRangeIsSelected ? formatDate(selectedRange.startDate) : startDate,
      isDateRangeIsSelected ? formatDate(selectedRange.endDate) : endDate,
      user?.getUserQuery?.trustee_id,
      selectSchool !== "" ? schoolId : "",
      status ? status?.toUpperCase() : "SUCCESS",
      getPaymentMode(filters.paymentMode, type),
      getPaymentMode(filters.paymentMode, type)?.includes("qr"),
    );
  }, [
    type,
    status,
    selectSchool,
    searchFilter,
    itemsPerRow,
    selectedRange,
    filters,
  ]);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    refetchDataFetch({
      start_date: isDateRangeIsSelected
        ? formatDate(selectedRange.startDate)
        : startDate,
      end_date: isDateRangeIsSelected
        ? formatDate(selectedRange.endDate)
        : endDate,
      page: currentPage.toString(),
      status: status?.toUpperCase(),
      limit: itemsPerRow.toString(),
      school_id: selectSchool === "" ? null : schoolId,
      payment_modes:
        Object.keys(filters.paymentMode).filter(
          (key) => filters.paymentMode[key],
        ).length > 0
          ? Object.keys(filters.paymentMode).filter(
              (key) => filters.paymentMode[key],
            )
          : null,
      isQrCode: getPaymentMode(filters.paymentMode, type)?.includes("qr"),
    });
  }, [currentPage, itemsPerRow]);

  useEffect(() => {
    if (searchText.length < 3) {
      setIsCustomSearch(false);
    } else {
      setIsCustomSearch(true);
    }
  }, [searchText]);

  return (
    <div className="overflow-hidden">
      <h2 className="text-[#1B163B] text-[28px] ml-4 font-[600]">
        Transactions
      </h2>
      <div className="w-full  grid xl:grid-cols-2 gap-4 mb-2">
        <div className="xl:col-span-1 col-span-2">
          <h2 className="text-[#1B163B] xl:text-[24px] text-lg ml-2  font-[400]">
            Transactions Amount
          </h2>

          <div className="text-[#229635] font-[400] flex items-center ">
            {/* {transactionReportLoading || refetchLoading ? (
              <img
                src={PriceLoading}
                className=" w-10 h-10 animate-spin"
                alt="loading"
              />
            ) : (
              <>
                
              </>
            )} */}

            <span className="xl:text-[44px] text-3xl flex items-center">
              <LiaRupeeSignSolid />{" "}
              {transactionAmountDetails !== null &&
              (status === "success" || status === null) ? (
                <span>
                  {transactionAmountDetails?.totalTransactionAmount.toLocaleString(
                    "hi-in",
                  )}
                </span>
              ) : (
                <span>0</span>
              )}
            </span>
            <span className="text-[20px] text-[#717171] flex items-center ml-2">
              {` (selected period )`}
            </span>
          </div>
        </div>

        <div className="xl:col-span-1 col-span-2">
          <h2 className="text-[#1B163B] xl:text-[24px] text-lg ml-2  font-[400]">
            Order Amount
          </h2>
          <div className="text-[#229635] font-[400] flex items-center ">
            <span className="xl:text-[44px] text-3xl flex items-center">
              <LiaRupeeSignSolid />
              {transactionAmountDetails !== null &&
              (status === "success" || status === null) ? (
                <span>
                  {transactionAmountDetails?.totalOrderAmount.toLocaleString(
                    "hi-in",
                  )}
                </span>
              ) : (
                <span>0</span>
              )}
            </span>
            <span className="text-[20px] text-[#717171] flex items-center ml-2">
              {` (selected period )`}
            </span>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto w-full">
        {transactionReportData ? (
          <_Table
            perPage={false}
            exportBtn={true}
            heading={"History"}
            pagination={false}
            copyContent={[4]}
            filter={[searchText]}
            loading={transactionReportLoading || refetchLoading}
            searchBox={
              <div className="w-full ">
                <div className="flex xl:!flex-row flex-col justify-between gap-2  w-full xl:items-center items-start mb-2">
                  <div className="bg-[#EEF1F6] py-3 items-center flex  px-3 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                    <input
                      className="text-xs pr-2 bg-transparent focus:outline-none w-full placeholder:font-normal"
                      type="text"
                      value={searchText}
                      placeholder=" Search(Order ID...)"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    {searchFilter !== "" && searchText.length > 3 && (
                      <HiMiniXMark
                        onClick={async () => {
                          setSearchFilter("");
                          setSearchText("");
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                        }}
                        className="text-[#1E1B59] cursor-pointer text-md mr-2 shrink-0"
                      />
                    )}
                    <Select
                      className="border-l-2 border-gray-400"
                      options={[
                        {
                          label: "By Order ID",
                          value: "custom_order_id",
                        },
                        { label: "By Edviron Order ID", value: "order_id" },
                        { label: "By Student Info", value: "student_info" },
                      ]}
                      isSearchable={false}
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e: any) => {
                        setSearchFilter(e.value.toLowerCase());
                        setCurrentPage(1);
                      }}
                      placeholder={
                        <div className="text-[#1E1B59] -mt-1 capitalize text-[10px]">
                          {searchFilter === ""
                            ? "filter by"
                            : searchFilter
                                  .toString()
                                  .toLowerCase()
                                  .replaceAll("_", " ") === "order id"
                              ? "Edviron Order ID"
                              : searchFilter
                                  .toString()
                                  .toLowerCase()
                                  .replaceAll("_", " ")}
                        </div>
                      }
                      value={searchFilter}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "transparent",
                          height: "20px",
                          border: "none",
                          boxShadow: "none",
                          cursor: "pointer",
                          minHeight: "10px",
                          padding: "0px",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          height: "20px",
                          width: "8rem",
                          padding: "0 8px",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: "0",
                          padding: "0",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          margin: "0",
                          padding: "0",
                          lineHeight: "20px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          lineHeight: "20px",
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: "20px",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "10px",
                          cursor: "pointer",
                        }),
                      }}
                    />

                    <div className="w-10 z-50 shrink-0 flex justify-center items-center">
                      {searchText.length > 3 && refetchLoading ? (
                        <AiOutlineLoading3Quarters className="text-xs animate-spin" />
                      ) : (
                        <i
                          onClick={() => {
                            if (
                              isCustomSearch === true &&
                              searchText.length > 3 &&
                              searchFilter !== ""
                            ) {
                              setCurrentPage(1);
                              refetchDataFetch({
                                isCustomSearch,
                                searchFilter: searchFilter,
                                searchParams: searchText,
                              });
                            }
                          }}
                          className=" cursor-pointer text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"
                        ></i>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end items-center flex-1 w-full max-w-[34rem]">
                    <TransactionDateFilter
                      setType={setDateRange}
                      type={dateRange}
                      refetch={() => {
                        refetchDataFetch({
                          start_date: formatDate(selectedRange.startDate),
                          end_date: formatDate(selectedRange.endDate),
                          status: status?.toUpperCase(),
                          school_id: schoolId === "" ? null : schoolId,
                          isQrCode: getPaymentMode(
                            filters.paymentMode,
                            type,
                          )?.includes("qr"),
                        });
                      }}
                      selectedRange={selectedRange}
                      setSelectedRange={setSelectedRange}
                      setIsDateRangeIsSelected={setIsDateRangeIsSelected}
                    />
                    <div className="w-full">
                      <Select
                        className="font-normal m-0 p-2 capitalize"
                        options={[
                          { label: "SUCCESS", value: "SUCCESS" },
                          { label: "PENDING", value: "PENDING" },
                          { label: "FAILED", value: "FAILED" },
                        ].map((status: any) => {
                          return {
                            label: status.label?.toLowerCase(),
                            value: status.value?.toLowerCase(),
                          };
                        })}
                        components={{
                          DropdownIndicator: CustomDropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e: any) => {
                          setStatus(e.value);
                          setCurrentPage(1);
                          refetchDataFetch({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            status: e.value?.toUpperCase(),
                            isCustomSearch: isCustomSearch,
                            searchFilter: searchFilter,
                            searchParams: searchText,
                            school_id: selectSchool === "" ? null : schoolId,
                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type,
                            ),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type,
                            )?.includes("qr"),
                          });
                        }}
                        placeholder={
                          <div className="text-[#1E1B59] text-xs">Status</div>
                        }
                        value={null}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            backgroundColor: "#F6F8FA",
                            border: "1px solid #1B163B",
                            borderRadius: "6px",

                            minHeight: "15px",
                            margin: "0px",
                            color: "#6687FF",
                          }),
                          valueContainer: (provided: any) => ({
                            ...provided,
                            padding: "0px",
                            paddingLeft: "0.5rem",
                          }),
                          input: (provided: any) => ({
                            ...provided,
                            backgroundColor: "transparent",
                            color: "#000",
                            "::placeholder": {
                              backgroundColor: "#YourDesiredColor",
                              opacity: 1,
                            },
                            placeholder: (provided: any) => ({
                              ...provided,
                              color: "red", // Set the color of the placeholder text
                            }),
                          }),
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <MixFilter
                        setSelectSchool={setSelectSchool}
                        setSchoolId={setSchoolId}
                        paymentModes={Object.keys(filters.paymentMode).filter(
                          (key) => filters.paymentMode[key],
                        )}
                        setType={setType}
                        onCancel={() => {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                        }}
                        onApply={() => {
                          console.log({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            status: status?.toUpperCase(),
                            limit: itemsPerRow.toString(),
                            school_id: selectSchool === "" ? null : schoolId,
                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type,
                            )?.includes("qr")
                              ? null
                              : getPaymentMode(filters.paymentMode, type),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type,
                            )?.includes("qr"),
                          });
                          refetchDataFetch({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            status: status?.toUpperCase(),
                            limit: itemsPerRow.toString(),
                            school_id: selectSchool === "" ? null : schoolId,
                            payment_modes: getPaymentMode(
                              filters.paymentMode,
                              type,
                            )?.includes("qr")
                              ? null
                              : getPaymentMode(filters.paymentMode, type),
                            isQrCode: getPaymentMode(
                              filters.paymentMode,
                              type,
                            )?.includes("qr"),
                          });
                        }}
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <RowsPerPageSelect
                    setItemsPerRow={setItemsPerRow}
                    itemsPerRow={itemsPerRow}
                    className=" justify-start"
                  />
                </div>
                <div className="flex items-center">
                  {type !== "" && (
                    <div className=" text-sm m-2  max-w-fit ">
                      <button
                        onClick={async () => {
                          setSelectedRange({
                            startDate: startOfDay(new Date()),
                            endDate: endOfDay(new Date()),
                            key: "selection",
                          });
                          if (status || schoolId) {
                            setType("");
                            refetchDataFetch({
                              start_date: startDate,
                              end_date: endDate,
                              status: status?.toUpperCase(),
                              school_id: schoolId === "" ? null : schoolId,
                            });

                            setFilters({
                              paymentMode: {
                                credit_card: false,
                                credit_card_emi: false,
                                upi: false,
                                wallet: false,
                                pay_later: false,
                                cardless_emi: false,
                                net_banking: false,
                                debit_card_emi: false,
                                debit_card: false,
                                na: false,
                              },
                            });

                            setIsDateRangeIsSelected(false);
                          } else {
                            refetchDataFetch({
                              start_date: startDate,
                              end_date: endDate,
                            });
                            setType("");
                            setFilters({
                              paymentMode: {
                                credit_card: false,
                                credit_card_emi: false,
                                upi: false,
                                wallet: false,
                                pay_later: false,
                                cardless_emi: false,
                                net_banking: false,
                                debit_card_emi: false,
                                debit_card: false,
                                na: false,
                              },
                            });
                            setIsDateRangeIsSelected(false);
                          }
                        }}
                        className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                      >
                        {type} <HiMiniXMark className=" text-lg ml-1" />
                      </button>
                    </div>
                  )}
                  {dateRange !== "" && (
                    <div className=" text-sm m-2  max-w-fit ">
                      <button
                        onClick={async () => {
                          setSelectedRange({
                            startDate: startOfDay(new Date()),
                            endDate: endOfDay(new Date()),
                            key: "selection",
                          });
                          if (status || schoolId) {
                            refetchDataFetch({
                              start_date: startDate,
                              end_date: endDate,
                              status: status?.toUpperCase(),
                              school_id: schoolId === "" ? null : schoolId,
                              payment_modes: getPaymentMode(
                                filters.paymentMode,
                                type,
                              ),
                              isQrCode: getPaymentMode(
                                filters.paymentMode,
                                type,
                              )?.includes("qr"),
                            });
                            setDateRange("");
                            setIsDateRangeIsSelected(false);
                          } else {
                            refetchDataFetch({
                              start_date: startDate,
                              end_date: endDate,
                            });
                            setDateRange("");
                            setIsDateRangeIsSelected(false);
                          }
                        }}
                        className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                      >
                        {dateRange} <HiMiniXMark className=" text-lg ml-1" />
                      </button>
                    </div>
                  )}
                  {selectSchool !== "" && (
                    <div className=" text-sm m-2  max-w-fit ">
                      <button
                        onClick={() => {
                          if (status || isDateRangeIsSelected || type) {
                            refetchDataFetch({
                              start_date: isDateRangeIsSelected
                                ? formatDate(selectedRange.startDate)
                                : startDate,
                              end_date: isDateRangeIsSelected
                                ? formatDate(selectedRange.endDate)
                                : endDate,
                              status: status?.toUpperCase(),

                              payment_modes: getPaymentMode(
                                filters.paymentMode,
                                type,
                              ),
                              isQrCode: getPaymentMode(
                                filters.paymentMode,
                                type,
                              )?.includes("qr"),
                            });
                            setSelectSchool("");
                            setSchoolId(null);
                          } else {
                            refetchDataFetch({
                              start_date: startDate,
                              end_date: endDate,
                            });
                            setSelectSchool("");
                            setSchoolId(null);
                          }
                        }}
                        className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                      >
                        {selectSchool} <HiMiniXMark className=" text-lg ml-1" />
                      </button>
                    </div>
                  )}
                  {status && (
                    <div className=" text-sm m-2  max-w-fit ">
                      <button
                        onClick={async () => {
                          setCurrentPage(1);
                          if (selectSchool || isDateRangeIsSelected) {
                            refetchDataFetch({
                              start_date: isDateRangeIsSelected
                                ? formatDate(selectedRange.startDate)
                                : startDate,
                              end_date: isDateRangeIsSelected
                                ? formatDate(selectedRange.endDate)
                                : endDate,
                              school_id: schoolId === "" ? null : schoolId,
                              payment_modes: getPaymentMode(
                                filters.paymentMode,
                                type,
                              ),
                              isQrCode: getPaymentMode(
                                filters.paymentMode,
                                type,
                              )?.includes("qr"),
                            });
                            setStatus(null);
                          } else {
                            refetchDataFetch({
                              start_date: startDate,
                              end_date: endDate,
                              payment_modes: getPaymentMode(
                                filters.paymentMode,
                                type,
                              ),
                              isQrCode: getPaymentMode(
                                filters.paymentMode,
                                type,
                              )?.includes("qr"),
                            });
                            setStatus(null);
                          }
                        }}
                        className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                      >
                        {status} <HiMiniXMark className=" text-lg ml-1" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            }
            data={[
              [
                "Sr.No",
                "Institute Name",
                "Date & Time",
                "Order ID",
                "Order Amt",
                "Transaction Amt",
                "Payment Method",
                // "Commission",
                "Status",
                "Student Name",
                "Phone No.",
                "Vendor Amount",
              ],
              ...transactionData?.map((row: any, index: number) => [
                <div>{row?.serialNumber}</div>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className="truncate"
                    key={row.orderID}
                  >
                    {row.schoolName}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className=" truncate"
                    key={row.orderID}
                  >
                    {row.payment_time
                      ? new Date(row?.payment_time).toLocaleString("hi", {
                          timeZone: "Asia/Kolkata",
                        })
                      : new Date(row?.updatedAt).toLocaleString("hi", {
                          timeZone: "Asia/Kolkata",
                        })}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className="truncate"
                    title={row.orderID}
                    key={row.orderID}
                  >
                    {row.custom_order_id ? row.custom_order_id : row.orderID}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >{`₹${row.orderAmount !== null ? (Math.floor(row?.orderAmount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>
                </Link>,

                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >{`₹${row.transactionAmount !== null ? (Math.floor(row?.transactionAmount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >
                    {row.isQRPayment ? "Dynamic QR Code" : row.paymentMode}
                  </div>
                </Link>,

                // <div className="text-center pr-4">{row?.commission}</div>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    className={`flex items-center capitalize ${
                      row.transactionStatus === "success"
                        ? "text-[#04B521]"
                        : row.transactionStatus === "failure" ||
                            row.transactionStatus === "failed"
                          ? "text-[#E54F2F]"
                          : row.transactionStatus === "pending"
                            ? "text-yellow-400"
                            : ""
                    }`}
                    key={row.orderID}
                  >
                    {row.transactionStatus?.replaceAll("_", " ")}
                    {row.isAutoRefund ? (
                      <img className="w-5 h-5 ml-[10px]" src={Aword} alt="a" />
                    ) : null}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >
                    {row.student_name ? row.student_name : "NA"}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                    key={row.orderID}
                  >
                    {row.student_phone ? row.student_phone : "NA"}
                  </div>
                </Link>,
                <Link to="/payments/transaction-receipt">
                  <div
                    onClick={() => {
                      setTransaction(row);
                    }}
                  >
                    {row.vendors_info?.length > 0
                      ? amountFormat(
                          getVendorAmount({
                            array: row?.vendors_info,
                            orderAmount: row?.orderAmount,
                          }),
                        )
                      : "NA"}
                  </div>
                </Link>,
              ]),
            ]}
            footer={
              <div className="flex justify-center items-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(
                    transactionReport?.getTransactionReport?.total_pages,
                  )}
                  onPageChange={handlePageChange}
                />
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
}
export default Transaction;
